import _react3 from "react";
import _paths3 from "./paths";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i];

    for (var key in source) {
      if (Object.prototype.hasOwnProperty.call(source, key)) {
        target[key] = source[key];
      }
    }
  }

  return target;
};

var _react = _react3;

var _react2 = _interopRequireDefault(_react);

var _paths = _paths3;

var _paths2 = _interopRequireDefault(_paths);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _objectWithoutProperties(obj, keys) {
  var target = {};

  for (var i in obj) {
    if (keys.indexOf(i) >= 0) continue;
    if (!Object.prototype.hasOwnProperty.call(obj, i)) continue;
    target[i] = obj[i];
  }

  return target;
}

var Icon = function Icon(_ref) {
  var _ref$name = _ref.name;
  var name = _ref$name === undefined ? "twitter" : _ref$name;
  var _ref$size = _ref.size;
  var size = _ref$size === undefined ? 16 : _ref$size;
  var _ref$fill = _ref.fill;
  var fill = _ref$fill === undefined ? "currentcolor" : _ref$fill;

  var props = _objectWithoutProperties(_ref, ["name", "size", "fill"]);

  var d = _paths2.default[name] || "";
  return _react2.default.createElement("svg", _extends({
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 16 16",
    width: size,
    height: size,
    fill: fill,
    fillRule: "evenodd",
    clipRule: "evenodd",
    strokeLinejoin: "round",
    strokeMiterlimit: "1.414"
  }, props), _react2.default.createElement("path", {
    fillRule: "nonzero",
    d: d
  }));
};

exports.default = Icon;
export default exports;
export const __esModule = exports.__esModule;